import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faSquareEnvelope } from '@fortawesome/free-solid-svg-icons';

const Icons = () => {
	return (
		<div>
			<a
				href="https://www.linkedin.com/in/noora-jumppanen/"
				target="_blank"
				rel="noopener noreferrer"
			>
				<FontAwesomeIcon icon={faLinkedin} size="2x" className="linkedin" />
			</a>
			<a
				href="https://github.com/NooraJumppanen"
				target="_blank"
				rel="noopener noreferrer"
			>
				<FontAwesomeIcon icon={faGithub} size="2x" className="github" />
			</a>
			<a
				href="mailto:noora.jumppanen@gmail.com"
				target="_blank"
				rel="noopener noreferrer"
			>
				<FontAwesomeIcon icon={faSquareEnvelope} size="2x" className="email" />
			</a>
		</div>
	);
};

export default Icons;
