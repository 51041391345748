import React from 'react';

const About = () => {
	return (
		<div className="background">
			<div className="about-wrapper">
				<div className="about-me">
					<h3>About me</h3>
					<p>
						I am a <b>Web Developer student</b> living in Porvoo, Finland. My
						studies at{' '}
						<b>
							Business College Helsinki at Full Stack Web Development programme
						</b>{' '}
						began in August 2021. Previously I had studied the basics of HTML,
						CSS, JavaScript, and Python in open university courses in 2020.
					</p>
					<h5>How did I end up studying programming?</h5>
					<p>
						I had been working in the <b>travel agency industry</b> in different
						positions including{' '}
						<b>sales, production, accounting, and office management</b> since
						2004, and I had enjoyed it, but in 2021 I decided it was time for me
						to try something else. The open university courses I took in 2020
						were just for fun, but the idea of programming for real had started
						to build in the back of my mind. My dream as a teenager was to
						become a graphic designer so I thought why not bring that dream
						alive with a twist?{' '}
						<b>
							I have a visual eye, I like problem-solving, and I am eager to
							learn new
						</b>
						, so I figured web development would suit me well. I found the Full
						Stack Web Development programme, applied, and was lucky to get in.
						It has been an exciting year! I really feel I am on the right path.
					</p>
					<h5>The future</h5>
					<p>
						I have been working as a Front-end Developer Intern since September 2022, and I will be graduating from my study programme in December 2022.
					
						I am looking for a junior position in Front-end Development starting from December 2022.
					</p>
					<h5>Skills</h5>
					<p>
						My skills include{' '}
						<b>
							HTML, CSS, JavaScript, React.js, Node.js, Git, GitHub, Jest,
							MySQL, and some TypeScript
						</b>
						. I also know the basics of{' '}
						<b>Figma, Photoshop, Illustrator and InDesign</b>. I have an
						entrepreneurial spirit when I am working. I am also very precise but
						not too strict or too serious. A good work atmosphere is something I
						value and cherish greatly. <b>Finnish</b> is my mother tongue and I
						speak <b>English</b> fluently.
					</p>
					<p>
						During my studies I contributed to two team projects. During the
						projects, I learned in practice about <b>Agile development</b> and{' '}
						<b>Scrum</b>, as well as working with Git and GitHub. I am
						presenting the projects on this website.
					</p>
				</div>
			</div>
		</div>
	);
};

export default About;
