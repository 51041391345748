import React from 'react';
import { useState, useEffect } from 'react';
import dashboard from '../images/dashboard_macbookframe.svg';
import survey from '../images/npsform_mobile.svg';
import images from '../images/movesit_images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import Carousel from 'react-bootstrap/Carousel';

const Projects = () => {
	const [index, setIndex] = useState(0);

	const [width, setWidth] = useState(window.innerWidth);
	const breakpoint = 1100;

	const handleSelect = (selectedIdx, e) => {
		setIndex(selectedIdx);
	};

	useEffect(() => {
		const handleWindowResize = () => setWidth(window.innerWidth);
		window.addEventListener('resize', handleWindowResize);
		return () => window.removeEventListener('resize', () => handleWindowResize);
	}, []);

	return (
		<div className="background">
			<div className="projects-section-wrapper">
				<h3>Projects</h3>
				<p>
					Here you can read about the most recent projects I have collaborated
					on. More of my projects can be viewed on my {''}
					<a
						className="textlink"
						href="https://github.com/NooraJumppanen"
						target="_blank"
						rel="noopener noreferrer"
					>
						GitHub page
					</a>
					.
				</p>

				<div id="nps">
					<h4>NPS Tracking</h4>
					<div className="screen_images">
						<img src={survey} alt="survey" className="project_img_vert" />
						<img src={dashboard} alt="dashboard" className="project_img_hori" />
					</div>
					<p>
						This was a team project we did for a partnering company PHZ Full
						Stack at Business College Helsinki in the spring of 2022. Several
						teams in our class had the same project to work on.
					</p>
					<p>
						The project's aim was to create an embeddable questionnaire form for
						our client (PHZ Full Stack) for measuring employees' Net Promoter
						Score (NPS), and a dashboard where our client could see the NPS of
						the previous 6 months.
					</p>
					<h5>The team</h5>
					<p>
						Our team was formed by our teacher and it consisted of four members.
						We were all developers, and I was more focusing on the UI. We had
						one preparation week and 4 x 2-week sprints. Scrum Master role was
						rotating so I also got to be a Scrum Master for one sprint. All
						teams had a common Product Owner from PHZ Full Stack.
					</p>
					<h5>Technologies</h5>
					<p>
						<i>questionnaire form: </i>React, CSS, Material UI, localStorage,
						Firebase, Backstopjs
					</p>
					<p>
						<i>dashboard: </i>React, CSS, Bootstrap, Firebase, Cypress
					</p>
					<p>
						<i>project management:</i> GitHub, Trello, Teams, Slack
					</p>
					<p>
						<i>deployment:</i> Netlify
					</p>
					<h5>What did I do?</h5>
					<ul>
						<li>
							designed and created the questionnaire form with React using
							Formik and Material UI
						</li>
						<li>
							added localStorage to prevent (or at least decrease) the
							possibility to answer the questionnaire more than once per
							calendar month
						</li>
						<li>
							created embedding code snippet with responsiveness using iframe
						</li>
						<li>designed the dashboard's UI wireframe with Figma</li>
						<li>coded dashboard's UI with CSS and a bit of Bootstrap</li>
						<li>
							made dashboard responsive for tablet size (mobile version was not
							needed by the client)
						</li>
						<li>
							made function for calculating the Net Promoter Score, and added a
							pie chart for visualising the score
						</li>
					</ul>
					<p>
						In addition to the list above, I helped my teammates with whatever I
						possibly could, for example setting up Firebase, and especially with
						Git and GitHub issues. I also took an active part in our daily
						meetings.
					</p>
					<h5>How did it go?</h5>
					<p>
						The project was definitely not an easy one. There were a lot of
						discussions and decisions to be made within our team on how to build
						a product that would satisfy the client's needs, but still be doable
						in the 9 weeks time frame that we had for the project. It took us a
						few weeks to get a clear vision of the product. None of us had done
						an application for someone else, and there were several technologies
						none of us had worked with before. Those technologies we
						deliberately chose for the sake of learning. Even though it was not
						that clear from the start, we kept on going forward. Bit by bit it
						all got clearer and we managed, in time, to build our product with
						the features our client had wished for. What I am especially happy
						about is that even though I barely knew my teammates beforehand, and
						we worked solely remotely via Teams, we stack together tightly and
						worked our way through all the difficulties together.
					</p>
					<h5>What I liked the most?</h5>
					<p>
						I really enjoyed designing with Figma, and coding with CSS the
						dashboard UI, as well as creating the questionnaire form. The form
						was a big chunk, and I am happy I got to learn Formik and Material
						UI because they both were unfamiliar to me.
					</p>

					<div className="link-wrapper">
						<a
							href="https://github.com/NooraJumppanen/highfives-frontend"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button className="ghlinkbutton">
								Questionnaire Form Code
								<FontAwesomeIcon icon={faGithub} size="2x" className="github" />
							</button>
						</a>

						<a
							href="https://github.com/NooraJumppanen/highfives-frontend"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button className="ghlinkbutton">
								Dashboard Code
								<FontAwesomeIcon icon={faGithub} size="2x" className="github" />
							</button>
						</a>
					</div>
				</div>
				<div id="movesit">
					<h4>MovesIT Dance App</h4>
					{width < breakpoint ? (
						<div className="carousel-container">
							<Carousel
								activeIndex={index}
								onSelect={handleSelect}
								interval={null}
								variant="dark"
							>
								{images.map((image) => (
									<Carousel.Item key={image.id}>
										<img
											className="d-lg-block w-100"
											src={image.name}
											alt="movesit app"
										/>
									</Carousel.Item>
								))}
							</Carousel>
						</div>
					) : (
						<div className="screen_images">
							{images.map((image, idx) => (
								<img
									className="project_img_vert"
									key={image.id}
									src={image.name}
									alt="movesit app"
								/>
							))}
						</div>
					)}

					<p>
						MovesIT is an application for storing dancer's names, their dance
						moves names, and Instagram video links. I built the application with
						a team at Business College Helsinki during the winter of 2021-2022.
						We built it for a specific dance group that one of our group members
						was part of.
					</p>
					<h5>The team</h5>
					<p>
						We had five members in our team, all developers. The client was a
						member of our team. Project Owner and Scrum Master roles were
						rotating per our 1-week sprints. We named our team Unicorn
						Production.
					</p>
					<h5>Technologies</h5>
					<p>
						<i>frontend:</i> React, CSS, HTML, Facebook Graph API
					</p>
					<p>
						<i>backend:</i> NodeJS, Express, TypeScript, MySQL
					</p>
					<p>
						<i>project management:</i> Figma, Trello, Teams, GitHub
					</p>
					<p>
						<i>deployment:</i> Heroku
					</p>
					<h5>What did I do?</h5>
					<ul>
						<li>created lo-fi wireframe with Figma</li>
						<li>
							frontend: coded the Add Move page (where the user inserts new
							information to the database) with React
						</li>
						<li>
							backend: created a function for importing old JSON format data to
							the new MySQL database with Node and TypeScript
						</li>
						<li>designed Unicorn Production logo and favicon with Figma</li>
						<li>
							created some smaller components for the app as well as involved in
							decisions regarding the UI, styling and accessibility
						</li>
					</ul>
					<h5>How did it go?</h5>
					<p>
						This was my first ever Agile team project. Luckily we had one member
						in the team who had worked in an Agile team before, so we managed to
						grasp on well from the get-go. The school had scheduled one day per
						week for this project for 9 weeks at the end of the year 2021. On
						the other days of the week we had classes, but we decided to have
						daily Scrum meetings before our classes started in the mornings.
						Even though for most of the days we did not have time to work on the
						project much, if at all, meeting classmates via Teams calls every
						day was socially important, as we were studying mostly remotely. The
						goal from the school's side was not to build a perfect product, but
						more of an MVP, and most imporantly to learn what it is like to work
						in a software development team. After 9 weeks we weren't yet where
						we wanted to be with the application, so we continued working until
						the application was working as our client, a member of our team,
						wanted. We presented our application in April of 2022 for Tech
						companies and fellow students at Business College Helsinki
						DemoSession afternoon. That was exciting! Yay, we did it!
					</p>
					<h5>What I liked the most?</h5>
					<p>
						When I designed the Unicorn Production logo for the landing page of
						the app, I was quite stressed at the time. But all my stress went
						away while I was designing the logo, and I was having so much fun! I
						don't have tattoos but if for some reason I would have to take one
						(what could be the reason? - no idea, but let's pretend there could
						be one), the logo could be it. That tattoo would resemble these
						early stages of my Tech career and it would remind me to always
						follow the joy. Like the kind of joy I had while being a part of the
						Unicorn Production team and creating this application.
					</p>
					<div className="link-wrapper">
						<a
							href="https://github.com/MariaRosenholm/movesit"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button className="ghlinkbutton">
								MovesIT Frontend Code
								<FontAwesomeIcon icon={faGithub} size="2x" className="github" />
							</button>
						</a>

						<a
							href="https://github.com/Termanty/MovesApp-backend"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button className="ghlinkbutton">
								MovesIT Backend Code
								<FontAwesomeIcon icon={faGithub} size="2x" className="github" />
							</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Projects;
