import addmove from './movesit_screenshots/movesit_addmove.svg';
import editmove from './movesit_screenshots/movesit_editmove.svg';
import landing from './movesit_screenshots/movesit_landing.svg';
import move from './movesit_screenshots/movesit_move.svg';
import search from './movesit_screenshots/movesit_search.svg';

const images = [
	{
		id: 1,
		name: landing,
	},
	{
		id: 2,
		name: search,
	},
	{
		id: 3,
		name: move,
	},
	{
		id: 4,
		name: editmove,
	},
	{
		id: 5,
		name: addmove,
	},
];

export default images;
