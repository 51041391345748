import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.scss';
import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Topnav from './components/Topnav';
import Footer from './components/Footer';

const App = () => {
	return (
		<BrowserRouter>
			<Topnav />
			<Routes>
				<Route index element={<Home />} />
				<Route path="/about" element={<About />} />
				<Route path="/projects" element={<Projects />} />
				<Route path="/contact" element={<Contact />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	);
};

export default App;
