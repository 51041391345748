import React from 'react';
import '../App.css';
import About from './About';
import Contact from './Contact';
import Projects from './Projects';

function Home() {
	return (
		<>
			<div className="background">
				<div className="herobanner-wrapper">
					<h1>Noora Jumppanen</h1>
					<h2>Web Developer &amp; Designer</h2>
				</div>
			</div>
			<About />
			<Projects />
			<Contact />
		</>
	);
}

export default Home;
