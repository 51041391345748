import React from 'react';
import Icons from '../components/Icons';

const Contact = () => {
	return (
		<div className="background">
			<div className="contact-wrapper">
				<div className="contact">
					<div className="contact-text">
						<h3>Contact</h3>
						<p>
							I'd love to hear from you if you think we could create something
							cool together, or if you just want to say hi!
						</p>
						<p>
							Feel free to contact me via LinkedIn or e-mail, and browse my
							projects on GitHub.
						</p>
					</div>
					<div className="iconswrapper">
						<Icons />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
