import React from 'react';
import Icons from './Icons';

const Footer = () => {
	return (
		<footer>
			<div className="iconswrapper">
				<Icons />
			</div>
			<div>&copy; 2022 Noora Jumppanen</div>
			<div className="unsplash-credit">
				Background photo by{' '}
				<a
					className="textlink"
					href="https://unsplash.com/es/@petraklapka?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Petra Klapka
				</a>{' '}
				on{' '}
				<a
					className="textlink"
					href="https://unsplash.com/s/photos/lilac?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Unsplash
				</a>
			</div>
		</footer>
	);
};

export default Footer;
