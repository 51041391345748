import React from 'react';
import logo from '../images/NJ_logo_darkpurple.svg';
import '../App.css';
import { NavLink } from 'react-router-dom';

const Topnav = () => {
	return (
		<header>
			<NavLink to="/">
				<img src={logo} alt="logo" className="logo" />
			</NavLink>
			<nav>
				<NavLink
					to="/about"
					className={({ isActive }) =>
						!isActive ? 'about-nav' : 'about-nav active'
					}
				>
					About
				</NavLink>
				<NavLink
					to="/projects"
					className={({ isActive }) =>
						!isActive ? 'projects-nav' : 'projects-nav active'
					}
				>
					Projects
				</NavLink>
				<NavLink
					to="/contact"
					className={({ isActive }) =>
						!isActive ? 'contact-nav' : 'contact-nav active'
					}
				>
					Contact
				</NavLink>
			</nav>
		</header>
	);
};

export default Topnav;
